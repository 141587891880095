import React from 'react';
import './layout.scss';
import Contact from "../Contact/Contact";
import SEO from "../Seo/Seo";
import ShareIt from "../Share/ShareIt";
import { Link } from "gatsby";

const Layout = ({ location, children, isBlogPost }) => (
  <React.Fragment>
    <SEO isBlogPost={isBlogPost}/>
    <div className={'container-fluid text-center'}>
      <div className="row justify-content-lg-center">
        <div className="col  col-lg-8">
          <ShareIt location={location}/>
          <h1 className={'my-2 py-4'}><Link className={"home-link"} to={'/'}>Cook with Pom</Link></h1>
        </div>
      </div>
      {children}
      <div className="row justify-content-lg-center">
        <div className="col  col-lg-8">
          <Contact/>
        </div>
      </div>
    </div>
  </React.Fragment>
);

export default Layout;

import React from 'react';
import { FaEnvelope, FaFacebookMessenger, FaLine, FaWhatsapp } from 'react-icons/fa';
import './contact.scss';
import { graphql, StaticQuery } from "gatsby";
import Img from 'gatsby-image';

const Contact = ({ data }) => {
  function callMe() {
    window.open('tel:+66102030405');
  }

  function mailMe() {
    window.open('mailto:me@mzil.com');
  }

  return (
    <React.Fragment>
      <h4 className={'contact-title mt-4 pt-2'}>Contact Me</h4>
      <div className="contacts row my-5">
        <div className="line contact col-12 col-md-4">
          <Img title={'Contact me on Line'} alt={'QR code Line'} width={'100%'} className={'qr-code d-none d-md-block'}
               sizes={data.imageLine.childImageSharp.sizes}/>
          <a href={"https://line.me/ti/p/_2VfMGoDWn"} rel="noopener noreferrer"
             target="_blank">
            <FaLine aria-label={"Line"} color={"#00B300"}/> pom1984hope
          </a>
        </div>
        <div className={'messenger contact col-12 col-md-4'}>
          <Img title={'Contact me on Messenger'} alt={'QR code Messenger'} width={'100%'}
               className={'qr-code d-none d-md-block'}
               sizes={data.imageMessenger.childImageSharp.sizes}/>
          <a
            href="https://m.me/pom.garden"
            rel="noopener noreferrer"
            target="_blank"
          >
            <FaFacebookMessenger aria-label={"Messenger"}  color={'blue'}/> pom.garden
          </a>
        </div>
        <div className={'whatsapp contact col-12 col-md-4'}>
          <Img title={'Contact me on WhatsApp'} alt={'QR code WhatsApp'} width={'100%'}
               className={'qr-code d-none d-md-block'}
               sizes={data.imageWhatsApp.childImageSharp.sizes}/>
          <a href={'#contact'} onClick={callMe}>
            <FaWhatsapp aria-label={"WhatsApp"} /> Call Me
          </a>
        </div>
        <div className={'mail contact col-12 col-md-12 text-center'}>
          <a href={'#contact'} onClick={mailMe}>
            <FaEnvelope aria-label={"Email"}  color={'red'}/> Send me an email
          </a>
        </div>
      </div>
    </React.Fragment>
  );
};

export default (props) => (
  <StaticQuery
    query={query}
    render={data => <Contact data={data} {...props} />}
  />
)


const query = graphql`
  query {
    imageMessenger: file(relativePath: { eq: "qr-code-messenger.png"}){
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        sizes(maxWidth: 512, maxHeight:512) {
          ...GatsbyImageSharpSizes
        }
      }
    }
    imageWhatsApp: file(relativePath: { eq: "qr-code-whatsapp.png"}){
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        sizes(maxWidth: 512, maxHeight:512) {
          ...GatsbyImageSharpSizes
        }
      }
    }
    imageLine: file(relativePath: { eq: "qr-code-line.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        sizes(maxWidth: 512, maxHeight:512) {
          ...GatsbyImageSharpSizes
        }
      }
    }
  }
`;

import React from 'react';
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';
import './share.scss';

const ShareIt = ({ location }) => {
  const url = location.href;
  const title = 'Great Cooking experience with Pom ...';
  const hashTags = ['cooking', 'thai', 'Chiang Mai'];
  return (
    <div className={'share-it'}>
      <FacebookShareButton url={url} quote={title}>
        <FacebookIcon size={32}/>
      </FacebookShareButton>
      <TwitterShareButton url={url} title={title} hashtags={hashTags}>
        <TwitterIcon size={32}/>
      </TwitterShareButton>
      <WhatsappShareButton url={url} title={title}>
        <WhatsappIcon size={32}/>
      </WhatsappShareButton>
    </div>
  );
};

export default ShareIt;
